<template>
  <div class="container-fluid pt-4">
    <div class="row">
      <div class="col-12 col-md-6">
        <div
          class="d-flex align-items-start align-items-md-end flex-column flex-md-row"
        >
          <div v-if="buildingID != null">
            <router-link
              :to="{
                name: 'Building Detail',
                params: { building_id: buildingID }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate" class="mr-2"
                ><i class="fa fa-chevron-left mr-1"></i>Back</a
              >
            </router-link>
          </div>
          <h1 class="mb-0 mr-2">{{ pageTitle }}</h1>
          <!-- <a href="#" class="mr-2" v-b-modal.form-update-floor v-b-tooltip.hover title="Update Floor Name"><i class="fa fa-edit"></i></a> -->
        </div>
      </div>
      <div class="col-12 col-md-6 mt-2 mt-md-0">
        <div
          class="d-flex align-items-center justify-content-start justify-content-md-end"
          style="margin-top: -8px"
        >
          <b-button
            variant="secondary"
            size="sm"
            class="mr-2"
            v-b-modal.form-update-floor
            v-b-tooltip.hover
            title="Update Floor Name"
          >
            <i class="fa fa-edit"></i>
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="mr-2"
            @click="confirmDeleteFloor"
            role="button"
            v-b-tooltip.hover
            title="Delete Floor"
            ><i class="fa fa-trash-alt"></i
          ></b-button>
          <router-link
            :to="{
              name: 'Floor Plan Editor',
              params: { building_id: buildingID, floor_id: floorID }
            }"
            v-slot="{ href, route, navigate }"
            custom
            v-if="buildingID != null"
          >
            <b-button
              class="mr-2"
              :href="href"
              @click="navigate"
              variant="secondary"
              size="sm"
              :disabled="!canPlotFloorPlan"
            >
              <i class="fa fa-map-marked mr-1"></i>Floor Plan Editor
            </b-button>
          </router-link>
          <div
            class="floor-image-thumbnail"
            :class="[
              floorData.default_img == null
                ? 'floor-image-empty'
                : 'floor-image-exist'
            ]"
          >
            <img
              :src="floorData.default_img"
              v-if="floorData.default_img != null"
              @click="showLightbox"
            />
            <div class="no-img pt-1" v-else>No image</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1 class="mb-1">Devices List</h1>
        <div
          class="d-inline-flex align-items-center mx-2"
          v-b-tooltip.hover
          title="Cameras"
        >
          <i class="fa fa-video mr-1"></i>
          <div class="floor-device-counter">{{ cameraList.length }}</div>
        </div>
        <div
          class="d-inline-flex align-items-center mr-2"
          v-b-tooltip.hover
          title="Doors"
        >
          <i class="fa fa-door-closed mr-1"></i>
          <div class="floor-device-counter">{{ controllerList.length }}</div>
        </div>
      </div>
      <div
        class="col-12 col-md-6 mt-2 mt-md-0 d-flex justify-content-start justify-content-md-end"
      >
        <div class="w-50 d-inline-block mr-2">
          <b-form-input
            id="search_bar"
            size="sm"
            type="text"
            placeholder="Search..."
            v-model="searchTerms"
            @input="searchDeviceList"
          ></b-form-input>
        </div>
        <b-dropdown
          v-show="showMoreActions"
          class="mr-2"
          variant="secondary"
          size="sm"
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v mr-2"></i>{{ selectedText }}
          </template>
          <b-dropdown-item-button
            v-b-modal.form-move-device
            @click="moveMultipleDevices"
            >Move</b-dropdown-item-button
          >
          <b-dropdown-item-button @click="confirmRemoveDevices"
            >Delete</b-dropdown-item-button
          >
        </b-dropdown>
        <b-button variant="success" size="sm" v-b-modal.form-add-device
          ><i class="fa fa-plus mr-1"></i>Add Device</b-button
        >
      </div>
    </div>
    <div class="device-list mt-2 scroller">
      <b-table-simple striped sticky-header="100%" class="table-floor-devices">
        <b-thead>
          <b-tr>
            <b-th style="width: 40px">
              <b-form-checkbox
                id="floors-select-all"
                v-model="allDevicesSelected"
                name="select_all_floors"
                :value="true"
                :unchecked-value="false"
                @change="selectAllDevices"
              ></b-form-checkbox>
            </b-th>
            <b-th>Device</b-th>
            <b-th class="w-20">Actions</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-if="floorDevicesList.length == 0" class="table-empty-row">
            <b-td colspan="3">No devices found</b-td>
          </b-tr>
          <b-tr v-for="(data, index) in floorDevicesList" :key="data.ip">
            <b-td>
              <b-form-checkbox
                :id="'devices-select-' + index"
                v-model="devicesSelected"
                name="select_devices"
                :value="data.type + '_' + data.ip"
                @change="selectSingleDevice"
              ></b-form-checkbox>
            </b-td>
            <b-td class="d-flex align-items-center">
              <div
                v-if="data.type == 'camera'"
                class="floor-device-icon floor-device-icon-camera mr-2"
              >
                <i class="fa fa-video"></i>
              </div>
              <div
                v-else-if="data.type == 'controller'"
                class="floor-device-icon floor-device-icon-door mr-2"
              >
                <i class="fa fa-door-closed"></i>
              </div>
              <div
                v-else-if="data.type == 'server'"
                class="floor-device-icon floor-device-icon-server mr-2"
              >
                <i class="fa fa-server"></i>
              </div>
              {{ data.ip
              }}<span v-if="data.name != data.ip" class="ml-1"
                >({{ data.name }})</span
              >
            </b-td>
            <b-td>
              <b-dropdown no-caret dropleft class="d-block d-md-none">
                <template #button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item
                  v-b-modal.form-update-device
                  @click="showUpdateDeviceForm(data)"
                >
                  <i class="fa fa-edit mr-1"></i> Update Device
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.form-move-device
                  @click="showMoveDeviceForm(data)"
                >
                  <i class="fa fa-share mr-1"></i> Move Device to another Floor
                </b-dropdown-item>
                <b-dropdown-item
                  @click="confirmDelete(data)"
                  class="text-danger"
                >
                  <i class="fa fa-times mr-1"></i> Remove Device from Floor
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                variant="secondary"
                size="sm"
                @click="showUpdateDeviceForm(data)"
                v-b-modal.form-update-device
                class="mr-1 d-none d-md-inline-block"
                ><i class="fa fa-edit mr-1"></i> Update</b-button
              >
              <b-button
                variant="secondary"
                size="sm"
                @click="showMoveDeviceForm(data)"
                v-b-modal.form-move-device
                class="mr-1 d-none d-md-inline-block"
                ><i class="fa fa-share mr-1"></i> Move</b-button
              >
              <b-button
                variant="danger"
                size="sm"
                @click="confirmRemoveDevice(data)"
                class="d-none d-md-inline-block"
                ><i class="fa fa-times mr-1"></i> Remove</b-button
              >
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <b-modal
      id="form-add-device"
      title="Add Device"
      ref="formAddDevice"
      @hide="resetFormAddDevice"
      centered
      hide-footer
    >
      <div class="d-flex">
        <b-form-group
          id="input-group-search-type"
          label="Type:"
          label-for="input-search-type"
          class="mr-2"
        >
          <b-form-select
            id="input-search-type"
            v-model="searchType"
            :options="searchTypeOptions"
            @change="searchDevices"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-search-ip"
          label="Search by IP:"
          label-for="input-search-ip"
          v-show="searchType != null"
        >
          <b-form-input
            id="input-search-ip"
            v-model="searchIP"
            type="text"
            placeholder="e.g. 192.168.0.123"
            required
            @change="searchDevices"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="device-available-container" v-show="searchType != null">
        <div>Devices available</div>
        <div class="device-options-container mt-2">
          <div
            v-for="device in searchDeviceOptions"
            :key="device.ip"
            class="device-option"
            :class="[selectedSearchDeviceOption == device.ip ? 'selected' : '']"
            @click="selectSearchDeviceOption(device)"
          >
            {{ device.ip }}
          </div>
        </div>
      </div>

      <b-form
        @submit="onSubmitFormAddDevice"
        :class="[selectedSearchDeviceOption != null ? '' : 'hide']"
        class="mt-2"
      >
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formAddDeviceData.name"
            type="text"
            placeholder="Enter device name"
            required
          ></b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="form-update-device"
      title="Update Device"
      ref="formUpdateDevice"
      @hide="resetFormUpdateDevice"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitFormUpdateDevice" class="mt-2">
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formUpdateDeviceData.name"
            type="text"
            placeholder="Enter device name or leave empty to use IP as default name"
          ></b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="form-move-device"
      :title="
        'Move ' +
        formMoveDeviceData.devices.length +
        ' device(s) to another Floor'
      "
      ref="formMoveDevice"
      @hide="resetFormMoveDevice"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitFormMoveDevice" class="mt-2">
        <b-form-group
          id="input-group-building"
          label="Building:"
          label-for="input-building"
          class="mr-2"
        >
          <b-form-select
            id="input-building"
            v-model="formMoveDeviceData.building"
            :options="buildingOptions"
            @change="loadFloorOptions"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-floor"
          label="Floor:"
          label-for="input-floor"
          class="mr-2"
        >
          <b-form-select
            id="input-floor"
            v-model="formMoveDeviceData.floor"
            :options="floorOptions"
            required
          ></b-form-select>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
    <!-- <b-modal id="form-add-floorplan" title="Add Floor Plan" ref="formAddFloorplan" @hide="resetFormAddFloorplan" centered hide-footer> -->
    <!--     <b-form @submit="onSubmitFormAddFloorplan" class="mt-2"> -->
    <!--         <b-form-group id="input-group-floorplan" label="Floor Plan Image:" label-for="input-floorplan"> -->
    <!--             <b-form-file v-model="formAddFloorplanData.floorplan" :state="Boolean(formAddFloorplanData.floorplan)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." accept="image/jpeg, image/png, image/gif"></b-form-file> -->
    <!--             <div class="mt-3">Selected file: {{ formAddFloorplanData.floorplan ? formAddFloorplanData.floorplan.name : '' }}</div> -->
    <!--         </b-form-group> -->
    <!--         <div class="text-right"> -->
    <!--             <b-button type="submit" variant="primary">Submit</b-button> -->
    <!--         </div> -->
    <!--     </b-form> -->
    <!-- </b-modal> -->
    <b-modal
      id="form-update-floor"
      title="Update Floor Name"
      ref="formUpdateFloor"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitFormUpdateFloor">
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formUpdateFloorData.name"
            type="text"
            placeholder="Enter floor name"
            required
          ></b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
    <CoolLightBox
      :items="floorPlanImages"
      :index="floorPlanIndex"
      @close="floorPlanIndex = null"
    >
    </CoolLightBox>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CoolLightBox from "vue-cool-lightbox";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
let utils = require("@/assets/global/js/utils.js");

export default {
  components: {
    CoolLightBox
  },
  data() {
    return {
      pageTitle: "",
      buildingID: null,
      buildingName: null,
      floorID: null,
      floorName: null,
      floorData: {},
      devicesSelected: [],
      allDevicesSelected: false,
      showMoreActions: false,
      selectedText: "",
      floorDevicesList: [],
      floorDevicesListOriginal: [],
      cameraList: [],
      controllerList: [],
      deviceCount: 0,
      searchTerms: "",
      floorPlanIndex: null,
      floorPlanImages: [],
      formAddDeviceData: {
        id: null,
        ip: null,
        type: null,
        name: null,
        status: null,
        data: null
      },
      formUpdateDeviceData: {
        type: null,
        ip: null,
        id: null,
        name: null
      },
      searchType: null,
      searchTypeOptions: [
        { value: null, text: "Please select an option" },
        { value: "camera", text: "Camera" },
        { value: "controller", text: "Controller" }
        // { value: "door", text: 'Door' },
        // { value: "reader", text: 'Reader' },
        // { value: "input", text: 'Input' },
      ],
      searchIP: "",
      searchDeviceOptions: [],
      selectedSearchDeviceOption: null,
      formMoveDeviceData: {
        devices: [],
        building: null,
        floor: null
      },
      buildingOptions: [],
      floorOptions: [],
      formUpdateFloorData: {
        name: null
      }
    };
  },
  computed: {
    ...mapGetters({
      isDevicesInitiated: "psim/isDevicesInitiated",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    canPlotFloorPlan: function () {
      var can_plot = false;
      if (this.floorDevicesList.length > 0) {
        can_plot = true;
      }
      return can_plot;
    },
    canPlotFloorPlanMsg: function () {
      if (this.canPlotFloorPlan == true) {
        return "Plot devices onto floor plan";
      } else {
        return "No devices assigned to this floor yet";
      }
    }
  },
  watch: {
    isDevicesInitiated: {
      handler: function (n, o) {
        if (n == true) {
          this.initView();
        }
      }
    }
  },
  mounted: function () {
    this.buildingID = parseInt(this.$route.params.building_id);
    this.floorID = parseInt(this.$route.params.floor_id);

    if (this.isDevicesInitiated == true) {
      this.initView();
    }
  },
  methods: {
    initView: function () {
      // console.log("Viewing SettingsFloorplan - FloorDetail");
      var $this = this;
      var building_obj = this.$store.getters["psim/getBuilding"](
        $this.buildingID
      );
      var floor_obj = this.$store.getters["psim/getFloor"](
        $this.buildingID,
        $this.floorID
      );
      $this.floorName = floor_obj.name;
      $this.floorData = floor_obj;
      $this.buildingName = building_obj.name;
      this.formUpdateFloorData.name = floor_obj.name;
      $this.pageTitle =
        "Manage Floor: " + building_obj.name + " - " + floor_obj.name;

      if ($this.floorData.default_img != null) {
        $this.floorPlanImages = [$this.floorData.default_img];
      }
      $this.fetchDevices();
    },
    fetchDevices: function () {
      var $this = this;
      $this.floorDevicesList = [];

      if ($this.buildingID != null) {
        var camera_list = this.$store.getters["psim/getCameras"]({
          building__id: $this.buildingID,
          floor__id: $this.floorID
        });
        var controller_list = this.$store.getters["psim/getControllers"]({
          building__id: $this.buildingID,
          floor__id: $this.floorID
        });
        $this.floorDevicesList = $this.floorDevicesList.concat(camera_list);
        $this.floorDevicesList = $this.floorDevicesList.concat(controller_list);
        $this.cameraList = camera_list;
        $this.controllerList = controller_list;
        // console.log(camera_list);
      }

      // deep copy array
      $this.floorDevicesListOriginal = JSON.parse(
        JSON.stringify($this.floorDevicesList)
      );

      // console.log("Fetched Device List");
      // end fetchDevices
    },
    showLightbox: function () {
      this.floorPlanIndex = 0;
    },
    searchDeviceList: function () {
      // console.log(this.searchTerms);
      var $this = this;
      if (this.searchTerms != null || this.searchTerms != "") {
        this.floorDevicesList = this.floorDevicesListOriginal.filter(function (
          x
        ) {
          var ip_includes = x.ip.includes($this.searchTerms);
          var name_includes = x.name
            .toUpperCase()
            .includes($this.searchTerms.toUpperCase());
          var type_includes = x.type
            .toUpperCase()
            .includes($this.searchTerms.toUpperCase());
          // console.log(x.name, name_includes)
          return ip_includes || name_includes || type_includes;
        });
      } else {
        this.fetchDevices();
      }
    },
    // --- Add device ---
    searchDevices: function (e) {
      var $this = this;

      var API_URL = $this.getAPIServerURL + "/api/monitorservice/status/";
      var RESULT_CATEGORY = "";
      var STORE_GETTER = "";
      var DEVICE_TYPE = "";

      $this.searchDeviceOptions = [];
      if ($this.searchType == "camera") {
        RESULT_CATEGORY = "DEV_CAM";
        STORE_GETTER = "psim/getCamera";
        DEVICE_TYPE = "camera";
      } else if ($this.searchType == "controller") {
        RESULT_CATEGORY = "DEV_CTRL";
        STORE_GETTER = "psim/getController";
        DEVICE_TYPE = "controller";
      }

      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((mdata) => {
          let category_result = null;
          //
          // console.log("mdata");
          // console.log(mdata);

          for (var service_url in mdata["result"]) {
            try {
              // console.log("service_url");
              // console.log(service_url);

              category_result =
                mdata["result"][service_url]["result"][RESULT_CATEGORY];
              //
              // console.log("category_result");
              // console.log(category_result);
            } catch (err) {
              //
            }

            if (category_result != null) {
              for (var i in category_result) {
                // console.log("i");
                // console.log(i);

                var device_url = i;

                // console.log("device_url");
                // console.log(device_url);

                var device_data = category_result[i];

                // console.log("device_data");
                // console.log(device_data);

                var device_ip = new URL(device_url).host;

                // console.log("device_ip");
                // console.log(device_ip);

                if (device_data.details != undefined) {
                  if (device_data.details.ip != undefined) {
                    device_ip = device_data.details.ip;
                  }
                }

                // console.log("device_ip");
                // console.log(device_ip);

                if ($this.$store.getters[STORE_GETTER](device_ip) == null) {
                  // console.log(
                  //   "$this.$store.getters[STORE_GETTER](device_ip) == null"
                  // );

                  var device_result = {
                    id: device_url,
                    ip: device_ip,
                    type: DEVICE_TYPE,
                    status: device_data["status"],
                    data: {
                      server_url:
                        device_data["monitor_url"] == undefined
                          ? null
                          : device_data["monitor_url"],
                      details:
                        device_data["details"] == undefined
                          ? null
                          : device_data["details"],
                      meta:
                        device_data["meta"] == undefined
                          ? null
                          : device_data["meta"]
                    }
                  };

                  // console.log("device_result");
                  // console.log(device_result);

                  $this.searchDeviceOptions.push(device_result);
                } else {
                  // console.log(
                  //   "X $this.$store.getters[STORE_GETTER](device_ip) == null"
                  // );
                }
              }
            }
          }

          $this.searchDeviceOptions.sort(function (a, b) {
            const numA = Number(
              a["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            const numB = Number(
              b["ip"]
                .split(".")
                .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
                .reduce((a, v) => ((a += v), a), 0)
            );
            return numA - numB;
          });
        })
        .catch((err) => {
          // console.log("err");
          // console.log(err);

          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    selectSearchDeviceOption: function (device_data) {
      this.selectedSearchDeviceOption = device_data.ip;
      this.formAddDeviceData.id = device_data.id;
      this.formAddDeviceData.ip = device_data.ip;
      this.formAddDeviceData.type = this.searchType;
      this.formAddDeviceData.name = device_data.ip;
      this.formAddDeviceData.data = device_data.data;
      // console.log(this.formAddDeviceData);
    },
    onSubmitFormAddDevice: function (e) {
      e.preventDefault();
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/devices/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, $this.formAddDeviceData)
        .then((data) => {
          if (data == null) {
            var device_data = {
              id: $this.formAddDeviceData.id,
              ip: $this.formAddDeviceData.ip,
              name: $this.formAddDeviceData.name,
              type: $this.formAddDeviceData.type,
              data: $this.formAddDeviceData.data,
              building: { id: $this.buildingID, name: $this.buildingName },
              floor: { id: $this.floorID, name: $this.floorName }
            };

            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: device_data.name + " has been added successfully",
              message_type: "success"
            });
            $this.$store.dispatch("psim/addDevice", {
              device_type: device_data.type,
              data: device_data
            });
          }
          // var device_data = {
          //     id: data.id,
          //     ip: data.ip,
          //     name: data.name,
          //     type: data.type,
          //     data: data.data,
          //     building: { id: $this.buildingID, name: $this.buildingName },
          //     floor: { id: $this.floorID, name: $this.floorName },
          // }

          // $this.$store.dispatch("psim/addFloor", {"building_id": $this.buildingID, "data": floor_data});
          // $this.$store.dispatch('session/addGlobalAlertMessage', { "message_text": device_data.name + " has been added successfully", "message_type": "success" });
          // $this.$store.dispatch('psim/addDevice', { device_type: device_data.type, data: device_data });
          $this.fetchDevices();

          $this.$refs.formAddDevice.hide();
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    resetFormAddDevice: function (e) {
      // this.formAddDeviceData.id = null;
      // this.formAddDeviceData.ip = null;
      // this.formAddDeviceData.type = null;
      // this.formAddDeviceData.name = null;
      // this.formAddDeviceData.data = null;

      this.selectedSearchDeviceOption = null;
      this.searchType = null;
      this.searchIP = "";
      this.searchDeviceOptions = [];
    },
    // --- Update device ---
    showUpdateDeviceForm: function (device_data) {
      this.formUpdateDeviceData.id = device_data.id;
      this.formUpdateDeviceData.ip = device_data.ip;
      this.formUpdateDeviceData.type = device_data.type;
      this.formUpdateDeviceData.name = device_data.name;
    },
    onSubmitFormUpdateDevice: function (e) {
      e.preventDefault();
      var $this = this;
      if (
        $this.formUpdateDeviceData.name == null ||
        $this.formUpdateDeviceData.name == ""
      ) {
        $this.formUpdateDeviceData.name = $this.formUpdateDeviceData.ip;
      }
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/devices/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdateDeviceData)
        .then((data) => {
          if (data == null) {
            var update_data = {
              device_type: $this.formUpdateDeviceData.type,
              device_id: $this.formUpdateDeviceData.id,
              data: {
                name: $this.formUpdateDeviceData.name
              }
            };
            $this.$store.dispatch("psim/updateDevice", update_data);
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated device successfully",
              message_type: "success"
            });
          }

          // TOCLEAN
          // } else {
          //     $this.$store.dispatch('session/addGlobalAlertMessage', { "message_text": data.detail, "message_type": "danger" });
          // }

          $this.fetchDevices();
          $this.$refs.formUpdateDevice.hide();
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    resetFormUpdateDevice: function (e) {
      this.formUpdateDeviceData.id = null;
      this.formUpdateDeviceData.ip = null;
      this.formUpdateDeviceData.type = null;
      this.formUpdateDeviceData.name = null;
    },
    // --- Move device ---
    showMoveDeviceForm: function (device_data) {
      this.formMoveDeviceData.devices = [device_data];

      var building_qs = this.$store.getters["psim/getBuildings"]();
      var building_options = [];
      for (var b in building_qs) {
        var building = building_qs[b];
        building_options.push({ value: building.id, text: building.name });
      }
      this.buildingOptions = building_options;
      this.formMoveDeviceData.building = this.buildingID;
      this.loadFloorOptions();
    },
    moveMultipleDevices: function () {
      var selected_devices = [];
      for (var i in this.devicesSelected) {
        var device_value = this.devicesSelected[i];
        var device_value_split = device_value.split("_");
        var device_type = device_value_split[0];
        var device_ip = device_value_split[1];
        var device_obj = null;
        if (device_type == "camera") {
          const device_obj_list = this.$store.getters["psim/getCameras"]({
            ip: device_ip
          });
          if (device_obj_list.length > 0) {
            device_obj = device_obj_list[0];
          }
          selected_devices.push(device_obj);
        } else if (device_type == "controller") {
          const device_obj_list = this.$store.getters["psim/getControllers"]({
            ip: device_ip
          });
          if (device_obj_list.length > 0) {
            device_obj = device_obj_list[0];
          }
          selected_devices.push(device_obj);
        }
      }

      this.formMoveDeviceData.devices = selected_devices;

      var building_qs = this.$store.getters["psim/getBuildings"]();
      var building_options = [];
      for (var b in building_qs) {
        var building = building_qs[b];
        building_options.push({ value: building.id, text: building.name });
      }
      this.buildingOptions = building_options;
      this.formMoveDeviceData.building = this.buildingID;
      this.loadFloorOptions();
    },
    loadFloorOptions: function () {
      var floor_qs = this.$store.getters["psim/getFloors"]({
        building_id: this.formMoveDeviceData.building
      });
      var floor_options = [];
      for (var b in floor_qs) {
        var floor = floor_qs[b];
        if (floor.id != this.floorID) {
          floor_options.push({ value: floor.id, text: floor.name });
        }
      }
      this.floorOptions = floor_options;
    },
    onSubmitFormMoveDevice: function (e) {
      e.preventDefault();
      var building = this.$store.getters["psim/getBuilding"](
        this.formMoveDeviceData.building
      );
      var floor = this.$store.getters["psim/getFloor"](
        this.formMoveDeviceData.building,
        this.formMoveDeviceData.floor
      );
      var to_location = {
        building: { id: building.id, name: building.name },
        floor: { id: floor.id, name: floor.name }
      };
      this.moveDevice(this.formMoveDeviceData.devices, to_location);
    },
    resetFormMoveDevice: function (e) {
      this.formMoveDeviceData.devices = [];
      this.formMoveDeviceData.building = null;
      this.formMoveDeviceData.floor = null;
    },
    moveDevice: function (devices, to_location) {
      var $this = this;
      var id_list = [];
      for (var d in devices) {
        id_list.push(devices[d].id);
      }

      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/move-devices/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, {
          id_list: id_list,
          floor_id: to_location.floor.id
        })
        .then((data) => {
          if (data == null) {
            for (var i in devices) {
              if (devices[i].type == "camera") {
                $this.$store.dispatch("psim/deleteDevice", {
                  device_type: "camera",
                  id: devices[i].id
                });
              } else if (devices[i].type == "controller") {
                $this.$store.dispatch("psim/deleteDevice", {
                  device_type: "controller",
                  id: devices[i].id
                });
              }

              var new_device_data = devices[i];
              // console.log(new_device_data);
              new_device_data["building"] = to_location["building"];
              new_device_data["floor"] = to_location["floor"];

              $this.$store.dispatch("psim/addDevice", {
                device_type: new_device_data.type,
                data: new_device_data
              });
            }
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Moved device successfully",
              message_type: "success"
            });
          } else {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Failed to move device",
              message_type: "danger"
            });
          }

          $this.showMoreActions = false;
          $this.selectedText = "";
          $this.devicesSelected = [];
          $this.fetchDevices();
          $this.$refs.formMoveDevice.hide();
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          if (err.status == 401) {
            $this.$store.dispatch("session/logoutSession");
          }
        });
    },
    // -- Remove device --
    confirmRemoveDevice: function (device_data) {
      // e.preventDefault();
      var $this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Confirm removing " +
            device_data.name +
            " from " +
            $this.floorName +
            "?",
          { centered: true }
        )
        .then((value) => {
          if (value == true) {
            var params = { cameras: [], controllers: [] };
            if (device_data.type == "camera") {
              params["cameras"].push(device_data.id);
            } else if (device_data.type == "controller") {
              params["controllers"].push(device_data.id);
            }

            $this.deleteDevice(params);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    confirmRemoveDevices: function (e) {
      e.preventDefault();
      var $this = this;
      if ($this.devicesSelected.length > 0) {
        $this.$bvModal
          .msgBoxConfirm(
            "Confirm removing " +
              $this.devicesSelected.length +
              " devices from " +
              $this.floorName +
              "?",
            {
              centered: true
            }
          )
          .then((value) => {
            // console.log(value);
            if (value == true) {
              var params = { cameras: [], controllers: [] };
              for (var i in $this.devicesSelected) {
                var device_value = $this.devicesSelected[i];
                var device_value_split = device_value.split("_");
                var device_type = device_value_split[0];
                var device_ip = device_value_split[1];
                let device_obj = null;
                if (device_type == "camera") {
                  const device_obj_list = $this.$store.getters[
                    "psim/getCameras"
                  ]({ ip: device_ip });
                  if (device_obj_list.length > 0) {
                    device_obj = device_obj_list[0];
                    params["cameras"].push(device_obj.id);
                  }
                } else if (device_type == "controller") {
                  const device_obj_list = $this.$store.getters[
                    "psim/getControllers"
                  ]({ ip: device_ip });
                  if (device_obj_list.length > 0) {
                    device_obj = device_obj_list[0];
                    params["controllers"].push(device_obj.id);
                  }
                }
              }
              $this.deleteDevice(params);
            }
          })
          .catch((err) => {
            // An error occurred
          });
      } else {
        $this.$bvModal.msgBoxOk("No devices selected", { centered: true });
      }
    },
    deleteDevice: function (device_params) {
      var $this = this;
      var id_list = [];
      id_list = device_params["cameras"].concat(device_params["controllers"]);
      // console.log(id_list);
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/devices/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL, { id_list: id_list })
        .then((data) => {
          if (data == null) {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Removed devices from floor",
              message_type: "success"
            });
            for (var i in device_params.cameras) {
              const camera_obj = $this.$store.getters["psim/getCameras"]({
                id: device_params.cameras[i]
              });
              if (camera_obj.length > 0) {
                const device_id = camera_obj[0].id;
                this.$store.dispatch("psim/deleteDevice", {
                  device_type: "camera",
                  id: device_id
                });
              }
            }
            for (var j in device_params.controllers) {
              const controller_obj = $this.$store.getters[
                "psim/getControllers"
              ]({ id: device_params.controllers[j] });
              if (controller_obj.length > 0) {
                const device_id = controller_obj[0].id;
                this.$store.dispatch("psim/deleteDevice", {
                  device_type: "controller",
                  id: device_id
                });
              }
            }
          }

          // TOCLEAN
          // } else {
          //     this.$store.dispatch('session/addGlobalAlertMessage', { "message_text": data.detail, "message_type": "danger" });
          // }

          $this.showMoreActions = false;
          $this.selectedText = "";
          $this.devicesSelected = [];
          $this.fetchDevices();
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          if (err.status == 401) {
            $this.$store.dispatch("session/logoutSession");
          }
        });
    },
    // -- Table row select --
    selectAllDevices: function (e) {
      var devices_id_list = [];
      if (e == true) {
        for (var i in this.floorDevicesList) {
          var device = this.floorDevicesList[i];
          var device_value = device.type + "_" + device.ip;
          devices_id_list.push(device_value);
        }
      }
      this.devicesSelected = devices_id_list;
      if (this.devicesSelected.length > 0) {
        this.showMoreActions = true;
        this.selectedText = this.devicesSelected.length + " selected";
      } else {
        this.showMoreActions = false;
        this.selectedText = "";
      }
    },
    selectSingleDevice: function (e) {
      if (this.devicesSelected.length > 0) {
        this.showMoreActions = true;
        this.selectedText = this.devicesSelected.length + " selected";
      } else {
        this.showMoreActions = false;
        this.selectedText = "";
      }
    },
    // -- Floor --
    confirmDeleteFloor: function (e) {
      e.preventDefault();
      var $this = this;
      $this.$bvModal
        .msgBoxConfirm(
          "All devices will be disassociated from " +
            $this.floorName +
            " and the floor plan image will be deleted as well. Confirm deleting " +
            $this.floorName +
            "?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value == true) {
            var API_URL =
              $this.getAPIServerURL +
              "/floorplans/buildings/" +
              $this.buildingID +
              "/floors/" +
              $this.floorID +
              "/";
            const client = $this.$root.getAjaxFetchClient();
            client
              .deleteRequest(API_URL)
              .then((data) => {
                $this.$store.dispatch("psim/deleteFloor", {
                  building_id: $this.buildingID,
                  floor_id: $this.floorID
                });
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Deleted floor successfully",
                  message_type: "success"
                });

                var floor_arrangement = [];
                var remaining_floors = $this.$store.getters["psim/getFloors"]({
                  building_id: $this.buildingID
                });
                for (var r in remaining_floors) {
                  floor_arrangement.push({
                    id: remaining_floors[r].id,
                    index: parseInt(r)
                  });
                  $this.$store.dispatch("psim/updateFloor", {
                    building_id: $this.buildingID,
                    floor_id: remaining_floors[r].id,
                    data: { index: parseInt(r) }
                  });
                }
                // console.log(floor_arrangement);
                if (floor_arrangement.length > 0) {
                  var API_URL2 =
                    $this.getAPIServerURL +
                    "/floorplans/buildings/" +
                    $this.buildingID +
                    "/floors/floors-index/";
                  client.postRequest(API_URL2, {
                    floor_ids: floor_arrangement
                  });
                }

                $this.$router
                  .push({
                    name: "Building Detail",
                    params: { building_id: $this.buildingID }
                  })
                  .catch(() => {});

                // TOCLEAN
                // } else {
                //     $this.$store.dispatch('session/addGlobalAlertMessage', { "message_text": data.detail, "message_type": "danger" });
                // }
              })
              .catch((err) => {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: err.detail,
                  message_type: "danger"
                });
                if (err.status == 401) {
                  $this.$store.dispatch("session/logoutSession");
                }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onSubmitFormUpdateFloor: function (e) {
      e.preventDefault();
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.floorID +
        "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdateFloorData)
        .then((data) => {
          // console.log(data);
          var update_data = {
            building_id: $this.buildingID,
            floor_id: $this.floorID,
            data: {
              name: $this.formUpdateFloor.name
            }
          };
          $this.$store.dispatch("psim/updateFloor", update_data);
          $this.$refs.formUpdateFloor.hide();
          $this.floorName = $this.formUpdateFloorData.name;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text:
              "Updated " + $this.formUpdateFloorData.name + " successfully",
            message_type: "success"
          });

          $this.formUpdateFloorData.name = null;
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          if (err.status == 401) {
            $this.$store.dispatch("session/logoutSession");
          }
        });
    }
  }
};
</script>
<style lang="scss">
@import "./SettingsFloorplan.scss";
</style>
